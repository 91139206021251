<template>
  <div class="content-wrapper">
    <div class="container">
      <div class="col-8">
        <h3 class="text-muted mt-4">Edição de Novidade</h3>
        <form @submit.prevent="updateData">
          <div class="form-group">
            <label for="title">Novo título</label>
            <input
              id="title"
              class="form-control form-control-sm"
              placeholder="Digite o novo título..."
              v-model="title"
            />
            <p>Mínimo 5 caracteres</p>
          </div>
          <div class="form-group">
            <label for="description">Nova descrição</label>
            <textarea
              id="description"
              class="form-control form-control-sm"
              placeholder="Digite a nova descrição..."
              v-model="content"
            ></textarea>
            <p>Máximo 200 caracteres</p>
          </div>
          <div class="form-group">
            <label for="image">Nova imagem</label>
            <br />
            <input type="file" @change="handleImageUpload" accept="image/*" />
            <p class="mt-2">Tamanho ideal (660x380px)</p>
          </div>
          <div class="form-group">
            <label for="redirect">Novo redirecionamento</label>
            <input
              id="redirect"
              class="form-control form-control-sm"
              placeholder="http://www.tallos..."
              v-model="helpLink"
            />
          </div>
          <div class="d-flex justify-content-start">
            <router-link to="/admin/news" class="nav-link">
              <button class="btn btn-outline-primary btnStyle mr-2">
                Cancelar
              </button>
            </router-link>
            <button type="submit" class="btn btn-outline-primary btnStyle ml-2">
              Editar
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      title: "",
      content: "",
      helpLink: "",
      imageBase64: null,
    };
  },

  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          const readerResult = reader.result;
          const base64String = readerResult.split(",")[1];
          this.imageBase64 = base64String;
        };
        reader.readAsDataURL(file);
      }
    },
    updateData() {
      const itemId = this.$route.params.id;
      const data = {
        title: this.title,
        content: this.content,
        fileBase64: this.imageBase64 || null,
        helpLink: this.redirect || null,
      };
      if (!this.title || !this.content) {
        this.$toasted.global.defaultError({
          msg: "Campos de Título e Descrição obrigatórios",
        });
        return;
      }
      const url = `${process.env.VUE_APP_URL_UPDATES_API}/news/${itemId}`;
      const vuexData = JSON.parse(localStorage.getItem("vuex"));
      const token = vuexData.tokenJWT;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(url, data, config)
        .then((response) => {
          this.title = "";
          this.content = "";
          this.helpLink = "";
          this.imageBase64 = "";
          this.$toasted.global.defaultSuccess({
            msg: "Novidade atualizada com sucesso!",
          });
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          this.$toasted.global.defaultError({
            msg: "Erro ao atualizar novidade.",
          });
        });
    },
  },
};
</script>

<style>
.nav-link {
  padding: 0px;
}
.btnStyle {
  width: 100px;
  height: 40px;
}
</style>
